import { gql, useQuery, useMutation, type ApolloError } from '@apollo/client'
import { useEffect } from 'react'
import type { CanSubmitChanged, Candidate } from './candidates'

export interface Participant {
  id: string
  idNumber: string
  name: string
  session: Session
}

export interface ScoreItem {
  id: string
  name: string
  maxScore: number
  order: number
}

interface Session {
  id: string
  name: string
  items: ScoreItem[]
}

export interface SessionWithCandidates extends Session {
  candidates: Candidate[]
}

const sessionFields = `
id name
items {
  id name maxScore order
}
`

export const JOIN_SESSION = gql`
mutation joinSession ($accessKey: String!, $idNumber: String!, $name: String!) {
  participant: joinSession (accessKey: $accessKey, details: { idNumber: $idNumber, name: $name }) {
    id
    idNumber
    name
    session {
      ${sessionFields}
    }
  }
}
`

export const GET_SESSION = gql`
query getSession ($id: ID!) {
  session (id: $id) {
    ${sessionFields}
    candidates { id name scoringOpen }
  }
}`

export const CANDIDATES_SUBSCRIPTION = gql`
subscription onCandidateAdded ($sessionId: ID!) {
  candidateAdded (sessionId: $sessionId) {
    id name scoringOpen
  }
}
`

export const OPENCLOSE_SUBSCRIPTION = gql`
subscription onCanSubmitChanged ($participantId: ID!) {
  canSubmitChanged (participantId: $participantId) {
    candidateId
    participantId
    scoreItemId
    status
  }
}`

interface UseJoinSessionReturns {
  joinSession: (variables: JoinSessionVars) => Promise<Participant>
  loading: boolean
  error?: ApolloError
}

export interface JoinSessionVars {
  accessKey: string
  idNumber: string
  name: string
}

export function useJoinSession (): UseJoinSessionReturns {
  const [mutate, { loading, error }] =
    useMutation<{ participant: Participant }, JoinSessionVars>(JOIN_SESSION)

  async function joinSession (variables: JoinSessionVars): Promise<Participant> {
    const { data } = await mutate({ variables })
    return data?.participant as Participant
  }

  return { joinSession, loading, error }
}

interface UseSessionReturns {
  loading: boolean
  session?: SessionWithCandidates
  error?: ApolloError
}

export function useSession (sessionId: string, participantId: string): UseSessionReturns {
  const { error, data, loading, subscribeToMore } =
    useQuery<{ session: SessionWithCandidates }, { id: string }>(
      GET_SESSION,
      {
        variables: { id: sessionId },
        pollInterval: 5000,
        fetchPolicy: 'cache-and-network'
      }
    )

  useEffect(() => {
    // return causes the 'unsubscribe' cleanup function to be returned
    return subscribeToMore<{ candidateAdded: Candidate }, { sessionId: string }>({
      document: CANDIDATES_SUBSCRIPTION,
      variables: { sessionId },
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data == null) return prev
        const newCand = subscriptionData.data.candidateAdded
        return {
          session: {
            ...prev.session,
            candidates: [...prev.session.candidates, newCand]
          }
        }
      }
    })
  }, [sessionId])

  useEffect(() => {
    return subscribeToMore<{ canSubmitChanged: CanSubmitChanged }, { participantId: string }>({
      document: OPENCLOSE_SUBSCRIPTION,
      variables: { participantId },
      updateQuery: (prev, { subscriptionData }) => {
        const payload = subscriptionData.data.canSubmitChanged

        if (payload.scoreItemId != null) {
          return prev
        }

        const prevCands = prev.session.candidates
        const idx = prevCands.findIndex(c => c.id === payload.candidateId)
        if (idx < 0) {
          return prev
        }

        const newCands = [...prevCands]
        newCands[idx] = {
          ...(prevCands[idx]),
          scoringOpen: payload.status
        }

        return {
          ...prev,
          session: {
            ...prev.session,
            candidates: newCands
          }
        }
      }
    })
  }, [participantId])

  return {
    loading,
    error,
    session: data?.session
  }
}
