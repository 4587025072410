import { useEffect } from 'react'
import { useParticipant } from './ParticipantContext'
import { Link, useNavigate } from 'react-router-dom'
import { useSession } from '../data/session'
import { LoadingSpinner } from './spinners'
import LostConnection from './LostConnection'

export default function SessionView (): JSX.Element {
  const navigate = useNavigate()
  const [participant] = useParticipant()

  useEffect(() => {
    if (participant == null) {
      navigate('/')
    }
  }, [participant])

  if (participant == null) {
    return <></>
  }

  const { loading, session, error } = useSession(participant.session.id, participant.id)

  if (loading) {
    return <LoadingSpinner />
  }

  if (session == null) {
    throw new Error('Session not returned by server.')
  }

  return (
    <>
      {error != null && <LostConnection />}
      <h4>Candidates</h4>

      {session.candidates.length > 0
        ? (
          <ol>
            {session.candidates.map(c => (
              <li key={c.id}>
                {c.scoringOpen
                  ? <strong><Link to={c.id}>{c.name}</Link> &ndash; Active</strong>
                  : <Link to={c.id}>{c.name}</Link>}
              </li>
            ))}
          </ol>
          )
        : (
          <p><em>No candidates yet; please wait for them to be added.</em></p>
          )}
    </>
  )
}
