import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloProvider as ActualApolloProvider,
  split
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import type { WithChildren } from '../lib/utils'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_URL,
  credentials: 'same-origin'
})

const wsLink = new GraphQLWsLink(createClient({
  url: import.meta.env.VITE_API_URL.replace(/^http/, 'ws'),
  shouldRetry: () => true,
  retryAttempts: 10
}))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors != null) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations?.toString() ?? ''}, Path: ${path?.toString() ?? ''}`)
        )
      }
      if (networkError != null) console.log(`[Network error]: ${networkError.message}`)
    }),
    createPersistedQueryLink({ sha256 }),
    splitLink
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Person: { keyFields: ['darn'] },
      Structure: { keyFields: ['type', 'code'] }
    }
  })
})

export const ApolloProvider = ({ children }: WithChildren): JSX.Element => (
  <ActualApolloProvider client={apolloClient}>
    {children}
  </ActualApolloProvider>
)
