import { Navbar, Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useParticipant } from './ParticipantContext'

const MainNav = (): JSX.Element => {
  const [participant, setParticipant] = useParticipant()
  const navigate = useNavigate()

  const handleExit = (): void => {
    setParticipant(undefined)
    navigate('/')
  }

  return (
    <>
      <Navbar bg='primary' variant='dark'>
        <img src='https://static.da-io.net/images/da-logo.png' alt='DA logo' height='38' />
        <LinkContainer to='/'>
          <Navbar.Brand>
            Selection Panel
          </Navbar.Brand>
        </LinkContainer>
      </Navbar>
      <Nav className='sub-nav'>
        {participant != null && (
          <>
            <Nav.Item>
              <Nav.Link disabled>Session: {participant.session.name}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={handleExit}>Exit</Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </>
  )
}

export default MainNav
