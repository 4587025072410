import { useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ChevronLeft } from 'react-bootstrap-icons'

import { useCandidateScores, useSubmitScore } from '../data/candidates'
import { useParticipant } from './ParticipantContext'
import { LoadingSpinner } from './spinners'
import LostConnection from './LostConnection'
import ScoreForm from './ScoreForm'
import { Alert, Row, Col } from 'react-bootstrap'

export default function CandidateView (): JSX.Element {
  const navigate = useNavigate()
  const [participant] = useParticipant()

  useEffect(() => { if (participant == null) navigate('/') }, [participant])
  if (participant == null) {
    return <></>
  }

  const { session } = participant

  const { candidateId } = useParams()
  if (candidateId == null) {
    throw new Error('CandidateView rendered without a candidateId.')
  }

  const { candidate, loading, error } = useCandidateScores({ candidateId, participantId: participant.id })
  const { submitScore } = useSubmitScore({ candidateId, participantId: participant.id })

  if (loading) {
    return <LoadingSpinner />
  }

  if (candidate == null) {
    throw new Error('No candidate returned from server.')
  }

  const handleScoreSubmit = async (scoreItemId: string, score: number): Promise<void> => {
    await submitScore({ scoreItemId, score })
  }

  const reopened = candidate.scores.some(s => s.reopened)
  const allFilled = candidate.scores.length === session.items.length

  const total = candidate.scores.reduce((prev, curr) => prev + curr.score, 0)
  const maxTotal = session.items.reduce((prev, curr) => prev + curr.maxScore, 0)

  return (
    <>
      <div><Link to='..'><ChevronLeft /> Back</Link></div>
      <h4>{candidate.name}</h4>

      {error != null && <LostConnection />}

      {candidate.scoringOpen
        ? (
            allFilled
              ? (<Alert variant='success'>Thank you for entering your scores.</Alert>)
              : (<Alert variant='info'>Please enter your scores now.</Alert>)
          )
        : (
            reopened
              ? (<Alert variant='info'>The Ombud has allowed you to re-enter one or more scores.</Alert>)
              : (<Alert variant='warning'>Entering of scores for this candidate is not open.</Alert>)
          )}

      {session.items.map(si => {
        const score = candidate.scores.find(s => s.scoreItem.id === si.id)
        return (
          <ScoreForm
            key={si.id}
            scoreItem={si}
            score={score?.score}
            disabled={!(candidate.scoringOpen || score?.reopened === true)}
            onSubmit={async (score) => { await handleScoreSubmit(si.id, score) }}
          />
        )
      })}

      <Row className='align-items-center py-2' style={{ fontWeight: 'bold' }}>
        <Col xs='auto'><div style={{ width: '16em' }}>Total:</div></Col>
        <Col xs='auto'>
          <div style={{ width: '6em', paddingLeft: '1em' }}>
            {allFilled ? total : ''}
          </div>
        </Col>
        <Col xs='auto'>/&nbsp;{maxTotal}</Col>
      </Row>

      {candidate.scoringOpen && allFilled && (
        <Alert variant='success'>Thank you, your scores have been submitted.</Alert>
      )}

    </>
  )
}
