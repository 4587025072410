import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import type { ScoreItem } from './session'
import { useEffect } from 'react'

const GET_CANDIDATE_SCORES = gql`
query getCandidateScores ($candidateId: ID!, $participantId: ID!) {
  candidate (id: $candidateId) {
    id
    name
    scoringOpen
    scores: participantScores (participantId: $participantId) {
      id
      scoreItem { id name maxScore order }
      score
      reopened
    }
  }
}
`

const SUBMIT_SCORE = gql`
mutation submitScore ($score: ScoreInput!) {
  score: submitScore (score: $score) {
    id
    scoreItem { id name maxScore order }
    score
    reopened
  }
}
`

export const CAN_SUBMIT_SUBSCRIPTION = gql`
subscription onCanSubmitChanged ($candidateId: ID!, $participantId: ID!) {
  canSubmitChanged (candidateId: $candidateId, participantId: $participantId) {
    candidateId
    participantId
    scoreItemId
    status
  }
}
`

export interface CanSubmitChanged {
  candidateId: string
  participantId?: string
  scoreItemId?: string
  status: boolean
}

export interface Candidate {
  id: string
  name: string
  scoringOpen: boolean
}

interface Score {
  id: string
  scoreItem: ScoreItem
  score: number
  reopened: boolean
}

interface ScoreInput {
  participantId: string
  candidateId: string
  scoreItemId: string
  score: number
}

interface CandidateScores extends Candidate {
  scores: Score[]
}

interface UseCandidateScoresArgs {
  participantId: string
  candidateId: string
}
interface UseCandidateScoresReturns {
  loading: boolean
  candidate?: CandidateScores
  error?: ApolloError
}

export function useCandidateScores (args: UseCandidateScoresArgs): UseCandidateScoresReturns {
  const { loading, error, data, subscribeToMore } =
    useQuery<{ candidate: CandidateScores }, UseCandidateScoresArgs>(
      GET_CANDIDATE_SCORES,
      {
        variables: args,
        pollInterval: 5000,
        fetchPolicy: 'cache-and-network'
      }
    )

  useEffect(() => {
    return subscribeToMore<{ canSubmitChanged: CanSubmitChanged }, UseCandidateScoresArgs>({
      document: CAN_SUBMIT_SUBSCRIPTION,
      variables: args,
      updateQuery: (prev, { subscriptionData }) => {
        const payload = subscriptionData.data.canSubmitChanged

        if (payload.scoreItemId === null) {
          return {
            candidate: {
              ...prev.candidate,
              scoringOpen: payload.status
            }
          }
        } else {
          const affectedScore = prev.candidate.scores.find(s => s.scoreItem.id === payload.scoreItemId) as Score
          return {
            candidate: {
              ...prev.candidate,
              scores: [
                ...prev.candidate.scores.filter(s => s.scoreItem.id !== payload.scoreItemId),
                { ...affectedScore, reopened: payload.status }
              ]
            }
          }
        }
      }
    })
  }, [args.candidateId, args.participantId])

  return { loading, error, candidate: data?.candidate }
}

interface SubmitScoreArgs {
  scoreItemId: string
  score: number
}
interface UseSubmitScoreReturns {
  loading: boolean
  error?: ApolloError
  submitScore: (args: SubmitScoreArgs) => Promise<Score>
}

export function useSubmitScore (args1: UseCandidateScoresArgs): UseSubmitScoreReturns {
  const [mutate, { loading, error }] = useMutation<{ score: Score }, { score: ScoreInput }>(
    SUBMIT_SCORE,
    { refetchQueries: [GET_CANDIDATE_SCORES] }
  )

  async function submitScore (args2: SubmitScoreArgs): Promise<Score> {
    const { data } = await mutate({ variables: { score: { ...args1, ...args2 } } })
    if (data == null) throw new Error('No data returned from submitScore mutation.')
    return data.score
  }

  return { loading, error, submitScore }
}
