import { Alert, Spinner } from 'react-bootstrap'

export default function LostConnection (): JSX.Element {
  return (
    <Alert variant='warning'>
      <Spinner animation='border' variant='primary' role='status' size='sm'>
        <span className='sr-only'>Reconnecting...</span>
      </Spinner>{' '}
      Lost connection. Please wait to reconnect, or refresh the page if this error doesn't go away.
    </Alert>
  )
}
