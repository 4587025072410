import { useState, useEffect } from 'react'
import { useJoinSession, type JoinSessionVars } from '../data/session'
import InjectTitle from './InjectTitle'
import { Button, Form, FormGroup, type FormControlProps, type FormProps, Alert } from 'react-bootstrap'
import { useParticipant } from './ParticipantContext'
import { useNavigate } from 'react-router-dom'
import { isValidSouthAfricanIDNumber } from 'validator-sa'

const validate = (field: keyof JoinSessionVars, value: string): boolean => {
  if (field === 'accessKey') return value.length === 4
  else if (field === 'idNumber') return isValidSouthAfricanIDNumber(value)
  else if (field === 'name') return value.length > 0
  else return false
}

const IndexView = (): JSX.Element => {
  const [participant, setParticipant] = useParticipant()
  const { loading, error, joinSession } = useJoinSession()
  const navigate = useNavigate()
  const [formState, setFormState] = useState<JoinSessionVars>({
    accessKey: '',
    name: '',
    idNumber: ''
  })
  const [submitAttempted, setSubmitAttempted] = useState(false)

  useEffect(() => {
    if (participant != null) {
      navigate('/session')
    }
  }, [participant])

  const handleFieldChange = (field: keyof JoinSessionVars): FormControlProps['onChange'] => {
    return (event) => {
      setFormState(state => ({
        ...state,
        [field]: event.target.value
      }))
    }
  }

  const handleFieldBlur = (field: keyof JoinSessionVars): React.FocusEventHandler<HTMLInputElement> => {
    return event => {
      const val = (field === 'idNumber') ? event.target.value.replace(/\D/g, '') : event.target.value.trim()
      setFormState(state => ({ ...state, [field]: val }))
    }
  }

  const accessKeyValid = validate('accessKey', formState.accessKey.trim())
  const idNumberValid = validate('idNumber', formState.idNumber.trim())
  const nameValid = validate('name', formState.name.trim())

  const handleSubmit: FormProps['onSubmit'] = (event) => {
    event.preventDefault()
    setSubmitAttempted(true)

    if (accessKeyValid && idNumberValid && nameValid) {
      joinSession({
        accessKey: formState.accessKey.trim().toUpperCase(),
        idNumber: formState.idNumber.replace(/\D/g, ''),
        name: formState.name.trim()
      })
        .then(participant => {
          setParticipant(participant)
          navigate('/session')
        })
        .catch(err => { console.log('Mutation error', err) })
    }
  }

  return (
    <>
      <InjectTitle title='' />

      <p>
        Welcome to the Selection Panel Scoring System.
        To join a session please fill in the details below.
        The chairperson of the session will provide you with the access code.
      </p>

      {(error != null) && (
        <Alert variant='danger'>
          Error: {error.message}
        </Alert>
      )}

      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup controlId='accessKey'>
          <Form.Label>Access code</Form.Label>
          <Form.Control
            placeholder='The access code for the session'
            required
            value={formState.accessKey}
            onChange={handleFieldChange('accessKey')}
            disabled={loading}
            isValid={accessKeyValid}
            isInvalid={(submitAttempted || formState.accessKey.length > 0) && !accessKeyValid}
          />
          <Form.Control.Feedback type='invalid'>
            Access code must be four letters.
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup controlId='idNumber'>
          <Form.Label>ID number</Form.Label>
          <Form.Control
            placeholder='Your South African ID number'
            required
            value={formState.idNumber}
            onChange={handleFieldChange('idNumber')}
            onBlur={handleFieldBlur('idNumber')}
            disabled={loading}
            isValid={idNumberValid}
            isInvalid={(submitAttempted || formState.idNumber.length > 0) && !idNumberValid}
          />
          <Form.Control.Feedback type='invalid'>
            Enter a valid SA ID number.
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder='Your full name'
            required
            value={formState.name}
            onChange={handleFieldChange('name')}
            onBlur={handleFieldBlur('name')}
            disabled={loading}
            isValid={nameValid}
            isInvalid={submitAttempted && !nameValid}
          />
          <Form.Control.Feedback type='invalid'>
            Enter your name.
          </Form.Control.Feedback>
        </FormGroup>

        <Button type='submit' variant='primary' disabled={loading}>Join Session</Button>
      </Form>
    </>
  )
}

export default IndexView
