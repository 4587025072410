import { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { LoadingSpinner } from './spinners'

import IndexView from './IndexView'
import SessionView from './SessionView'
import CandidateView from './CandidateView'
// For lazy loading:
// const StructureView = lazy(async () => await import('./StructureView'))

const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path='/' element={<IndexView />} />
      <Route path='/session'>
        <Route index element={<SessionView />} />
        <Route path=':candidateId' element={<CandidateView />} />
      </Route>
    </Routes>
  </Suspense>
)

export default AppRoutes
