import { createContext, useContext, useState, useEffect } from 'react'
import { WithChildren } from '../lib/utils'
import type { Participant } from '../data/session'

type ContextData = ReturnType<typeof useState<Participant>>

const ParticipantContext = createContext<ContextData>([undefined, () => {}])

export function ParticipantProvider ({ children }: WithChildren): JSX.Element {
  const [participant, setParticipant] = useState<Participant | undefined>(() => {
    const value = window.localStorage.getItem('current-participant')
    return value != null ? (JSON.parse(value) as Participant) : undefined
  })

  useEffect(() => {
    if (participant == null) {
      window.localStorage.removeItem('current-participant')
    } else {
      window.localStorage.setItem('current-participant', JSON.stringify(participant))
    }
  }, [participant])

  return (
    <ParticipantContext.Provider value={[participant, setParticipant]}>
      {children}
    </ParticipantContext.Provider>
  )
}

export function useParticipant (): ContextData {
  return useContext(ParticipantContext)
}
