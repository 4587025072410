import { useCallback, useState } from 'react'
import { Form, Row, Col, FormControlProps, Spinner } from 'react-bootstrap'
import { CheckLg, XLg } from 'react-bootstrap-icons'
import { debounce } from 'lodash'
import { ScoreItem } from '../data/session'

interface ScoreFormProps {
  scoreItem: ScoreItem
  score?: number
  disabled?: boolean
  onSubmit?: (score: number) => Promise<void>
}

type Status = 'blank' | 'submitting' | 'error' | 'ok'

export default function ScoreForm (props: ScoreFormProps): JSX.Element {
  const {
    scoreItem,
    score,
    disabled = false,
    onSubmit = async () => {}
  } = props

  const [value, setValue] = useState<string>(score != null ? score.toString() : '')
  const [status, setStatus] = useState<Status>(score != null ? 'ok' : 'blank')
  const [error, setError] = useState('')

  const debouncedSubmit = useCallback(
    debounce((valueString: string) => {
      const value = parseInt(valueString)
      if (isNaN(value)) {
        setStatus('error')
        setError('You must enter a number.')
      } else if (value !== parseFloat(valueString)) {
        setStatus('error')
        setError('You must enter a whole number, not a fraction.')
      } else if (value < 0) {
        setStatus('error')
        setError('You must not enter a negative number.')
      } else if (value > scoreItem.maxScore) {
        setStatus('error')
        setError(`The maximum score is ${scoreItem.maxScore}.`)
      } else {
        setStatus('submitting')
        setError('')
        onSubmit(value)
          .then(() => { setStatus('ok') })
          .catch(err => {
            setStatus('error')
            setError(err.message)
          })
      }
    }, 750),
    [onSubmit]
  )

  const handleChange: FormControlProps['onChange'] = e => {
    const valueString = e.target.value
    setValue(valueString)
    debouncedSubmit(valueString)
  }

  const labelParts = scoreItem.name.split(':')

  return (
    <>
      <Row className='align-items-center py-2'>
        <Col xs='auto'>
          <div style={{ width: '16em' }}>
            <span style={{ fontWeight: 'bold' }}>{labelParts[0]}</span>:
            {labelParts[1] != null && labelParts[1]}:
          </div>
        </Col>
        <Col xs='auto'>
          <div style={{ width: '6em' }}>
            <Form.Control
              type='number'
              min={0}
              max={scoreItem.maxScore}
              step={1}
              value={value}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col xs='auto'>/&nbsp;<span style={{ fontWeight: 'bold' }}>{scoreItem.maxScore}</span></Col>
        <Col xs={1}>
          <div style={{ width: 65 }}>
            {status === 'ok' && <span className='text-success'><CheckLg size='35' /></span>}
            {status === 'error' && <span className='text-danger'><XLg size='35' /></span>}
            {status === 'submitting' && <Spinner animation='border' variant='primary' />}
          </div>
        </Col>
      </Row>
      {status === 'error' && (
        <div className='text-danger'>
          {error}
        </div>
      )}
    </>
  )
}
